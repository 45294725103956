// setting
const breakPointSp = 900;
const mql = window.matchMedia(`screen and (min-width: ${breakPointSp + 1}px)`);
let pcFlg;

let $body = $('body');
let $container = $('.l-container');

if (mql.matches) {
  pcFlg = true;
} else {
  pcFlg = false;
}

let _DATA = {};

const setSessionStorage = () => {
  sessionStorage.setItem('yutori_data', JSON.stringify(_DATA));
};

const dataStorage = sessionStorage.getItem('yutori_data');
if (dataStorage) {
  _DATA = JSON.parse(dataStorage);
}

const Common = {
  ready() {
    $body = $('body');
    textSplit('.js-split');
    this.userAgent.check.browser();
    Menu.init();
    $body.addClass('is-ready');
    let parameter = $(location).attr('search');
    // if ('?op=skip' == parameter) {
    //   $body.addClass('is-op-skip');
    // }

    var keyName = 'visited';
    var keyValue = true;

    if (!sessionStorage.getItem(keyName)) {
      //sessionStorageにキーと値を追加
      sessionStorage.setItem(keyName, keyValue);

      //ここに初回アクセス時の処理
      $('body').addClass('is-op');
    } else {
      //ここに通常アクセス時の処理
      $('body').addClass('is-op-skip');
    }
  },
  load() {
    this.smoothScroll(0, 1000, 'easeOutExpo');
    $body.addClass('is-load');
  },
  updateSize() {
    Win.width = window.innerWidth;
    Win.height = window.innerHeight;
  },
  // handleMediaQuery(mql) {
  //   if (mql.matches) {
  //     pcFlg = true;
  //     if (Menu.observe == undefined) {
  //       Menu.observe.init();
  //     }
  //   } else {
  //     pcFlg = false;
  //   }
  // },
  userAgent: {
    browser: undefined,
    check: {
      browser() {
        const ua = navigator.userAgent;
        if (ua.toLowerCase().indexOf('msie') !== -1 || ua.toLowerCase().indexOf('trident') !== -1) {
          Common.userAgent.browser = 'ie';
        } else if (ua.toLowerCase().indexOf('edge') !== -1) {
          Common.userAgent.browser = 'edge';
        } else if (ua.toLowerCase().indexOf('chrome') !== -1) {
          Common.userAgent.browser = 'chrome';
        } else if (ua.toLowerCase().indexOf('safari') !== -1) {
          Common.userAgent.browser = 'safari';
        } else if (ua.toLowerCase().indexOf('firefox') !== -1) {
          Common.userAgent.browser = 'firefox';
        } else {
          Common.userAgent.browser = 'other';
        }
      }
    },
  },
  scrollEffect: {
    instance: undefined,
  },
  smoothScroll(gap, duration, easing) {
    let urlHash = location.hash;
    gap = 59.5 * 2.3;
    if (window.innerWidth <= 900) {
      gap = 63.5 * 1.8;
    }
    if (urlHash) {
      setTimeout(function () {
        let $_target = $(urlHash);
        if (!$_target.length) return;
        let $_targetY = $_target.offset().top - gap;
        $('body,html').stop().animate({ scrollTop: $_targetY }, duration, easing);
      }, 50);
    }
    $('.js-innerlink').on('click', function () {
      $('body').removeClass('is-nav-open');
      let target = $(this).attr('data-link');
      let position = $(target).offset().top - 50;
      $('html, body').stop(true).animate({
        scrollTop: position
      }, 1500, 'easeInOutCubic');
    });
    $('a[href*="#"]').on('click', function () {
      let $_target = $(this.hash);
      if (!$_target.length) return;
      let $_targetY = $_target.offset().top - gap;
      $('html,body').animate({ scrollTop: $_targetY }, duration, easing);
      return true;
    });
  },
  // 配列の要素をランダムに並び替える
  shuffleArray(array) {
    for (let i = (array.length - 1); 0 < i; i--) {

      let r = Math.floor(Math.random() * (i + 1));

      let tmp = array[i];
      array[i] = array[r];
      array[r] = tmp;
    }
    return array;
  }
};
mql.addListener(Common.handleMediaQuery);

let Menu = {
  isOpen: false,
  config: {
    $toggle: $('.js-nav-button'),
    $bg: $('.l-nav__bg'),
  },
  open() {
    $body.addClass('is-menu-open');
    this.config.$toggle.attr('aria-expanded', true);
    openModal();
    this.isOpen = true;
    $('.l-nav__menu-item').on('click', function () {
      let navOpen = $('body').hasClass('is-menu-open');
      if (navOpen === true) {
        setTimeout(function () {
          $('body').removeClass('is-menu-open');
        }, 150);
      }
      closeModal();
    });
  },
  close() {
    setTimeout(function () {
      $body.removeClass('is-menu-open');
    }, 150);
    closeModal();
    this.config.$toggle.attr('aria-expanded', false);
    this.isOpen = false;
  },
  // observe: {
  //   instance: undefined,
  //   switchPoint: document.getElementById('js-nav-show-point'),
  //   options: {
  //     root: null,
  //     rootMargin: '0px 0px -100% 0px',
  //     threshold: 0
  //   },
  //   whenIntersect(entries, object) {
  //     entries.forEach(function (entry, i) {
  //       if (entry.isIntersecting) {
  //         $body.addClass('is-scrolled');
  //       } else {
  //         $body.removeClass('is-scrolled');
  //       }
  //     });
  //   },
  //   init() {
  //     let _this = this;
  //     _this.observer = new IntersectionObserver(_this.whenIntersect, _this.options);
  //     _this.observer.observe(_this.switchPoint);
  //   }
  // },
  attachEvent() {
    this.config.$toggle.on('click', (e) => {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });

    this.config.$bg.on('click', (e) => {
      this.close();
    });
  },
  init() {
    if ($('.l-hamburger').length) {
      this.attachEvent();
      // this.observe.init();
    }
  },
};

let accordion = ((slideSpeed) => {
  $(document).on('click', '.js-accordion', (e) => {
    $(e.currentTarget).next().stop().slideToggle(slideSpeed);
    $(e.currentTarget).toggleClass('is-open');
  });

  // 閉じるボタン押下時
  $(document).on('click', '.js-accordion-close', (e) => {
    let $_head = $(e.currentTarget).parents('.js-close-target').prev();
    $_head.removeClass('is-open');
    $(e.currentTarget).parents('.js-close-target').slideToggle(slideSpeed);
    $('html,body').animate({ scrollTop: $_head.offset().top }, slideSpeed);
  });
});

let zeroPadding = ((num) => {
  return ('00' + num).slice(-2);
});

function openModal() {
  let _beforeOpenWidth = $(window).width();
  $body.css('overflow', 'hidden');
  let _afterOpenWidth = $(window).width();
  let _diff = _afterOpenWidth - _beforeOpenWidth;

  if (_diff > 0) {
    $body.css('paddingRight', `${_diff}px`);
  }
}

function closeModal() {
  $body.css('overflow', '');
  $body.css('paddingRight', '0px');
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

// text split
function textSplit(target) {
  let _target = document.querySelectorAll(target);
  let _nodes = [];

  for (let i = 0; i < _target.length; i++) {
    _nodes.push(_target[i].childNodes);
  }

  let _htmlList = [];
  for (let i = 0; i < _nodes.length; i++) {
    let _html = [];
    for (let index = 0; index < _nodes[i].length; index++) {
      if (_nodes[i][index].nodeType === 3) {
        let _text = _nodes[i][index].textContent;
        let _array = _text.split('');
        for (let _i = 0; _i < _array.length; _i++) {
          _html.push('<span>' + _array[_i] + '</span>');
        }
      } else {
        _html.push(_nodes[i][index].outerHTML);
      }
    }
    _htmlList.push(_html);
  }

  $(target).each(function (index, val) {
    $(this).html(_htmlList[index]);
  });
}

$(function () {
  accordion(400);
  Common.ready();
});

$(window).on('load', function () {
  Common.load();
});

// ウィンドウの横幅が変わった時に実行
$(window).on('resize', function () {
});